import React from "react";
import { Helmet } from "react-helmet";

import Location from "../images/icons8-marker-100.png";
import Phone from "../images/icons8-phone-100.png";
import Mail from "../images/icons8-mail-100.png";

function Contact() {
  return (
    <section
      className="relative overflow-visible bg-gray-100 py-12"
      id={"contact"}
    >
      <Helmet>
        <meta
          name="description"
          content="Contact us for any of your waterproofing needs. We work all over the Bay Area, including San Francisco, San Rafael, San Anselmo, Tiburon, Mill Valley, Novato, Fairfax, Sausalito, Ross, Corte Madera, Petaluma, Richmond, Berkeley and Oakland"
        />
      </Helmet>
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2"></div>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:pt-20">
          <div className="max-w-3xl mx-auto text-center pb-8 md:pb-12">
            <h1 className="h2 text-4xl pt-40 font-medium uppercase tracking-[.09em]">
              Contact Us
            </h1>
          </div>

          <div className="mx-6 my-6 md:my-16">
            <div className="flex flex-col md:flex-row">
              <div className="text-lg md:text-xl w-full md:w-1/2 bg-blue-900 text-white text-center rounded-md px-6 m-auto py-6 tracking-wide ">
                Contact us for any of your waterpoofing needs. We are based in
                San Rafael, CA and are available for work in Marin and Sonoma
                County, San Francisco, and the East Bay.
              </div>
              <div className="w-full md:w-1/2 mx-2 md:mx-6 items-start">
                <div className="flex my-10">
                  <img src={Location} className="w-12 mx-6" alt="location" />
                  <div className="text-lg my-auto">Based in San Rafael, CA</div>
                </div>
                <div className="flex my-10">
                  <img src={Phone} className="w-12 mx-6" alt="phone" />
                  <div className="text-lg my-auto">Call at (415) 302 5542</div>
                </div>

                <div className="flex my-10">
                  <img src={Mail} className="w-12 mx-6 center" alt="mail" />
                  <div className="text-lg my-auto ">
                    Email dwcbuild@gmail.com or click{" "}
                    <a
                      className="hover:underline"
                      href="mailto:dwcbuild@gmail.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
