import React from "react";
import { Helmet } from "react-helmet";

// Media
import Check from "../images/icons8-checkmark-96.png";

export default function AboutUs() {
  return (
    <section
      className="relative overflow-visible bg-gray-100 my-12 py-12"
      id={"about"}
    >
      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <Helmet>
        <meta
          name="description"
          content="We are a general contracting firm that’s been in business for 26 years. We specialize in waterproofing but do a wide variety of services for our clients. The owner has almost 50 years of experience in construction and has a strong background in all phases of residential construction.
          Our knowledge and experience in the building trade enable us to bring added value to any waterproofing job. Our areas of expertise are decks over living space, carports, and shower pans. We are skilled in demolition, the layout of drains, slope, and flashing details."
        />
      </Helmet>
      <div
        className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 pointer-events-none"
        aria-hidden="true"
      ></div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">
          <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform -translate-y-1/2"></div>
          <div className="max-w-6xl mx-auto text-center pb-12 md:pb-8">
            <h2 className="h2 text-4xl mb-4 pt-20 font-medium uppercase tracking-[.09em]">
              About Us
            </h2>
          </div>
          <div className="flex flex-col md:flex-row items-center">
            <div
              className="text-xl text-gray-600 w-full md:w-2/3 px-8 pb-8 md:pb-0"
              data-aos="fade-right"
            >
              <p>
                We are a general contracting firm that’s been in business for 26
                years. We specialize in waterproofing but do a wide variety of
                services for our clients. The owner has almost 50 years of
                experience in construction and has a strong background in all
                phases of residential construction.
              </p>
              <p className="pt-16">
                Our knowledge and experience in the building trade enable us to
                bring added value to any waterproofing job. Our areas of
                expertise are decks over living space, carports,
           and shower pans. We are skilled in
                slope, the layout of drains, and flashing details.
              </p>
            </div>

            <div className="w-full md:w-1/3 px-4 py-8" data-aos="fade-left">
              <ul className="mx-auto items-start bg-blue-900 flex-col rounded-xl pl-4 py-4">
                <li className="flex p-4">
                  <img src={Check} className="w-8 h-8" alt="check" />
                  <p className="text-white text-lg mx-4">
                    Fully Insured and Licensed
                  </p>
                </li>
                <li className="flex p-4">
                  <img src={Check} className="w-8 h-8" alt="check" />
                  <p className="text-white text-lg mx-4">
                    Family Owned and Operated
                  </p>
                </li>
                <li className="flex p-4">
                  <img src={Check} className="w-8 h-8" alt="check" />
                  <p className="text-white text-lg mx-4">
                    Wide Array of Services Offered
                  </p>
                </li>
                <li className="flex p-4">
                  <img src={Check} className="w-8 h-8" alt="check" />
                  <p className="text-white text-lg mx-4">
                    Decades of Experience
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
