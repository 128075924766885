import React from "react";
import { Helmet } from "react-helmet";

import HeroImage from "../gallery/image13.jpg";

function HeroHome() {
  return (
    <section
      className="pt-10 px-0 md:px-0 max-w-screen-2xl pb-4 md:pb-20"
      id={"home"}
    >
      <Helmet>
        <title>DWC</title>
      </Helmet>
      <div>
        <div
          className="flex justify-center mb-8 mt-8 mx-0 px-0"
          data-aos="zoom-y-out"
          data-aos-delay="450"
        >
          <img
            className="mx-0 w-full h-auto bg-fixed bg-cover bg-center"
            src={HeroImage}
            alt="a wetsuit roof with Mt. Tam in the distance"
          />
          <div className="absolute mx-auto px-0 sm:px-6">
            <div className="pt-24 pb-12 md:pt-40 md:pb-20">
              <div className="text-center pb-12 md:pb-16">
                <div
                  className="text-2xl md:text-5xl font-extrabold mb-4 mx-aut text-white"
                  data-aos="zoom-y-out"
                >
                  DWC
                  <div className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-teal-400">
                    Duncan Wilson Construction
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
