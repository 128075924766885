import React, { useState } from "react";

// Media
import image1 from "../gallery/image1.jpg";
import image2 from "../gallery/image2.jpg";
import image3 from "../gallery/image3.jpg";
import image4 from "../gallery/image4.jpg";
import image5 from "../gallery/image5.jpg";
import image9 from "../gallery/image9.jpg";
import image12 from "../gallery/image12.jpg";
import image13 from "../gallery/image13.jpg";
import image14 from "../gallery/image14.jpg";
import image15 from "../gallery/image15.jpg";
import image16 from "../gallery/image16.jpg";

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image9,
  image12,
  image13,
  image14,
  image15,
  image16,
];

const alt = [
  "tiled deck",
  "grey urethane deck",
  "tiled deck aerial",
  "indoor fireplace with marble tiling",
  "redwood deck railing",
  "sand colored urethane deck",
  "up close shot of grey urethane deck with white wooden rails",
  "applying the sandcoat of a urethane deck overlooking Sausalito",
  "finished grey urethane deck",
  "finished grey urethane deck",
  "floating wooden deck made of redwood, in the process of being built",
];

export default function Gallery() {
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = images.length - 1;
    } else if (newIndex >= images.length) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  const buttonStyles =
    "px-4 py-2 m-4 bg-[#d97706] rounded-md text-white text-md md:text-2xl font-medium uppercase tracking-[.09em] font-mono";

  return (
    <section className="relative overflow-visible my-12 py-12" id={"gallery"}>
      <div
        className="absolute inset-0 pointer-events-none mb-16"
        aria-hidden="true"
      ></div>
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2"></div>
      <div className="max-w-3xl mx-auto text-center pb-8 md:pb-12">
        <h1 className="h2 text-4xl pt-40 font-medium uppercase tracking-[.09em]">
          Our Work
        </h1>
      </div>
      <img
        className="max-h-[400px] md:max-h-[600px] mx-auto relative"
        src={images[activeIndex]}
        alt={alt[activeIndex]}
        rel="preload"
      />
      <span className="flex flex-row absolute w-full px-8 md:px-0 md:top-1/2 justify-between">
        <button
          className={buttonStyles}
          onClick={() => {
            updateIndex(activeIndex - 1);
          }}
        >
          Prev
        </button>
        <button
          className={buttonStyles}
          onClick={() => {
            updateIndex(activeIndex + 1);
          }}
        >
          Next
        </button>
      </span>
    </section>
  );
}
