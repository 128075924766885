import "./App.css";

import NavBar from "./Components/NavBar.jsx";
import HeroHome from "./Components/HeroHome";
import Services from "./Components/Services";
import AboutUs from "./Components/AboutUs";
import Gallery from "./Components/Gallery";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";

function App() {
  return (
    <div>
      <header>
        <NavBar />
      </header>
      <HeroHome />
      <Services />
      <AboutUs />
      <Gallery />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
