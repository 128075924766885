import React, { useState, useEffect } from "react";

function NavBar() {
  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 6 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  const textStyles =
    "font-medium font-sans text-sm md:text-md uppercase md:tracking-[.1em] text-gray-600 hover:text-gray-900 px-2 md:px-5 py-1 md:py-3 flex items-center transition duration-150 ease-in-out";
    return (
    <header
      className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top && "bg-white backdrop-blur-sm shadow-lg"
      }`}
    >
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-between h-16 md:h-20">
          <nav className="flex flex-grow">
            <ul className="flex flex-grow justify-between md:justify-end flex-wrap items-center">
              <li>
                <a href="#home" className={textStyles}>
                  Home
                </a>
              </li>
              <li>
                <a href="#services" className={textStyles}>
                  Services
                </a>
              </li>
              <li>
                <a href="#about" className={textStyles}>
                  About
                </a>
              </li>
              <li>
                <a href="#gallery" className={textStyles}>
                  Gallery
                </a>
              </li>

              <li>
                <a href="#contact" className={textStyles}>
                  Contact
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default NavBar;
