import React from "react";

function Footer() {
  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* Copyrights note */}
        <div className="text-right px-4 py-4 md:py-8 border-t border-gray-200 text-sm text-gray-600 mr-4">
          Made by DWC. All rights reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
