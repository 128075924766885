import React from "react";
import { Helmet } from "react-helmet";
// Media
import Water from "../images/icons8-water-64.png";
import Building from "../images/icons8-building-64.png";

export default function Services() {
  return (
    <section className="relative overflow-visible my-12 py-12" id={"services"}>
      <Helmet>
        <meta
          name="description"
          content="Wateproofing for Decks
      We provide waterproofing for walking decks such as urethane decks, neoprene decks that serve as a pre-proof undertile and concrete, and floating wooden decks.
      found
      General Construction
      We provide a variety of general construction services such as tiling, and building decks."
        />
      </Helmet>
      <div
        className="absolute inset-0 pointer-events-none mb-16"
        aria-hidden="true"
      ></div>
      <div className="absolute left-0 right-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2"></div>
      <div className="relative h-full max-w-6xl mx-auto px-4 sm:px-6">
        <div className="h-full pt-12 md:pt-20">
          <div className="max-w-3xl mx-auto text-center pb-8 md:pb-8">
            <h1 className="h2 text-4xl pt-40 font-medium uppercase tracking-[.09em]">
              Services
            </h1>
          </div>
          <div className="my-6 md:my-16" data-aos="fade-down">
            <div className="flex flex-col md:flex-row">
              <div className="w-full md:w-1/2 text-center px-8 text-xl font-semibold mb-6">
                <img src={Water} className="mx-auto mb-6" alt="water" />
                Waterproofing for Decks
                <p className="text-lg font-light">
                  We provide waterproofing for walking decks such as urethane
                  decks, neoprene decks that serve as a pre-proof undertile and
                  concrete, and floating wooden decks.
                </p>
              </div>

              <div className="w-full md:w-1/2 text-center px-8 text-xl font-semibold mb-6">
                <img src={Building} className="mx-auto mb-6" alt="build" />
                General Construction
                <p className="text-lg font-light">
                  We provide a variety of general construction services such as
                  tiling, and building decks.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
